Vue.filter('numberFormat', function (value) {
    if (typeof value !== 'undefined' && value !== null) {
        value = value + '';
        var exploded = value.split('.');

        var result = exploded[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        if (typeof (exploded[1]) !== 'undefined') {
            result += '.' + exploded[1];
        }

        return result;
    }

    return null;
});
